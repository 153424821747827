.finishsubmittest {
    overflow: hidden;
}

.bgg .peoplesubmit {
    opacity: 50%;
    height: 70vh;
    width: 65vh;
}

.bgg {
    text-align: center;
    margin-top: 45px;
    position: relative;
}

.Jawabantersimpan {
    margin-top: 29vh !important;
    position: relative !important;

    border-radius: 10px;
    background-color: #cb243e;
    margin: auto;
    /* width: 50vw; */
    min-width: 415px;
    width: 40vw;
    height: 350px;
    max-width: 600px;
    max-height: 350px;
    color: white;
    text-align: center;

    /* box-sizing: border-box; */
    padding-top: 20px;
    min-height: 270px;
}

.thanks-finishsubmit h6 {
    font-size: 120%;
}

@media only screen and (max-width: 700px) {
    .bgg .peoplesubmit {
        opacity: 50%;
        height: 60vh;
        width: 60vw;
        overflow: hidden;
    }

    .Jawabantersimpan {
        border-radius: 10px;
        width: 75vw;
        height: 300px;
        margin-top: 25vh !important;
        /* height: 210px; */
        max-width: 380px;
        font-size: 70%;

        color: white;
        /* padding-top: 5vw; */
        padding: 10px;
        margin: auto;
        min-width: 0px;
    }

    .thanks-finishsubmit h6 {
        font-size: 140%;
        margin-bottom: 0px;
    }
    /* min-height: 0px; */
    .bgg {
        text-align: center;
        margin-top: 0px;
    }
}

@media only screen and (max-width: 300px) {
    .Jawabantersimpan {
        font-size: 30%;
        height: 5vh;
    }

    .thanks-finishsubmit h2 {
        font-size: 300%;
    }
}
