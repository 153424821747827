.card-importExcel {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#drop-area {
  width: 100%;
  height: 100%;
  margin: 20px auto;
  text-align: center;
  line-height: 100px;
  border: 2px dashed #ccc;
  cursor: pointer;
  max-height: 100px;
}


