.containerstartpage {
    margin-right: -50px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
}

.buttonmulai {
    background-color: #ffcc2a;
    border-radius: 8px;
    border: none;
    margin-top: 20px;
    width: 2%;
    max-width: 200px;
    /* max-height: 40px; */
    padding: 5px;
    font-weight: 1000;
    font-size: 150%;
}

.buttonmulai:hover {
    background-color: rgb(255, 196, 0);
}
.start {
    padding-top: 19vh;
    width: 1000vw;
    padding-left: 18vw;
    position: absolute;
    display: inline-block;
}

.start p {
    padding-top: 10px;
    /* font-size: xx-large; */
    font-size: 3vw;
}

.start h1 {
    /* font-size: 300%; */
    font-size: 4vw;
    font-weight: 1000;
}

.bgstartpage {
    display: inline-block;
    margin-left: 58vw;
    margin-top: 17vh;
    /* margin-right: -10px; */
    overflow: hidden;
    /* position:relative; */
}
.gambarstartpage {
    /* position: absolute; */
    /* position: absolute; */
    width: 100%;
    height: 100%;
    overflow: hidden;

    /* margin-right: 45px; */
    /* max-height: 475px; */
    /* display: inline-block; */

    margin-top: 0px;
    /* z-index:inherit; */

    /* left: 710px;
    top: 228px; */
}

.card-m-test {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    padding: 20px;
    margin: 20px;
}

.card-m-test h2 {
    margin-top: 0;
    color: #333;
}

.card-m-test ol {
    padding-left: 10px;
}

.card-m-test ol li {
    margin-bottom: 10px;
    line-height: 1.6;
    color: #555;
}

/* btn mulai */
.uiverse {
  position: relative;
  background: #dc3545;
  color: #000;
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.uiverse:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

svg:hover span,
svg:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.uiverse:hover,
.uiverse:hover .tooltip,
.uiverse:hover .tooltip::before {
  background: linear-gradient(320deg, #dc3545, #e7462a);
  color: #ffffff;
}

@media only screen and (max-width: 700px) {
    .buttonmulai {
        background-color: #ffcc2a;
        border-radius: 8px;
        border: none;
        margin-top: 0px;
        width: 2%;
        max-width: 60px;

        padding: 5px;
        font-weight: 1000;
        font-size: 50%;
    }

    .start {
        padding-top: 20vh;
        width: 1000vw;
        padding-left: 15vw;
        position: absolute;
        display: inline-block;
    }

    .start p {
        padding-top: 10px;
        /* font-size: xx-large; */
        font-size: 3vw;
    }

    .start h1 {
        /* font-size: 300%; */
        font-size: 4vw;
        font-weight: 1000;
    }
}

@media only screen and (max-width: 576px) {
    .buttonmulai {
        background-color: #ffcc2a;
        border-radius: 8px;
        border: none;
        margin-top: 0px;
        width: 20vw;
        max-width: 100px;

        padding: 2vw;
        font-weight: 1000;
        font-size: 3vw;
    }
    .start {
        padding-top: 18vh;
        width: 1000vw;
        padding-left: 10vw;
        /* text-align: center; */
    }

    .start p {
        padding-top: 10px;
        /* font-size: xx-large; */
        font-size: 8vw;
    }

    .start h1 {
        /* font-size: 300%; */
        font-size: 8vw;
        font-weight: 1000;
    }

    .bgstartpage {
        /* display: inline-block; */
        margin-left: 50vw;
        margin-top: 15vh;
        /*margin-right: -10px*/
    }

    .gambarstartpage {
        width: 80vw;
        height: 50vh;
        margin-top: 0px;
    }
}
