.container-mbti {
    padding: 0px 50px;
    max-width: 1200px;
    margin: auto;
    min-width: 298px;
}

/* <--------------data diri top section---------------> */

.data-diri-MBTI-section {
    background-color: #cb243e;
    color: aliceblue;
    margin-top: 50px;

    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    min-width: 290px;
}
#data-diri-MBTI {
    display: flex;
    flex-direction: row;
    margin-left: -20px;
    display: inline-block;
    font-size: 1.1em;

    overflow: hidden;
}

/* #data-diri-MBTI ul li {
    list-style: none;
    display: table;
    margin-bottom: 10px;
} */

#data-diri-MBTI ul {
    list-style: none;
    /* display: table; */
    margin-bottom: 10px;
}

#data-diri-MBTI li {
    display: table-row;
    margin-bottom: 500px;
}

#data-diri-MBTI b {
    display: table-cell;
    padding-right: 1em;
    margin-bottom: 20px;
}

#data-diri-MBTI p {
    display: table-cell;
    padding-right: 10px;
}

/* <--------------end data diri top section---------------> */
/* <--------------MBTI---------------> */
.mbti {
    text-align: center;
    margin-bottom: 50px;
}
.mbti h1 {
    font-size: 5em;
}
.mbti h5 {
    font-size: 1.5em;
}
/* <--------------end MBTI---------------> */
/* <--------------Karakter---------------> */
.karakter {
    margin: auto;
    background-color: crimson;
    width: 100%;
    min-width: 290px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px;

    margin-bottom: 50px;

    text-align: center;
    font-size: 1.5em;
    color: aliceblue;
    padding-top: 10px;
    box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    -webkit-box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    -moz-box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
}

.karakter h1 {
    margin-top: 20px;
    /* font-size: 3vw; */
}

.karakter ul {
    margin-top: 30px;

    text-align: left;
}

/* <--------------end of karakter---------------> */

/* <--------------Saran pekerjaan-----------------> */

.saran-pekerjaan {
    display: grid;

    background-color: crimson;
    width: 100%;
    min-width: 290px;
    margin: auto;
    border-radius: 10px;
    color: aliceblue;
    padding: 30px 10px 30px 50px;

    box-sizing: border-box;
    font-size: large;
    box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    -webkit-box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    -moz-box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    margin-bottom: 50px;
}

.pekerjaan {
    text-align: center;
    margin-bottom: 30px;
    margin-left: -10px;
}

.list-pekerjaan {
    text-align: center;
    font-size: 1.4em;
}

.list-pekerjaan ul li {
    width: 150px;
}

/* <-------------- END Saran pekerjaan-----------------> */

@media only screen and (max-width: 1181px) {
    .list-pekerjaan ul li {
        width: 26%;
        margin-left: 12%;
    }
}

/* ------------RESPONSIVE PHONE------------- */

@media only screen and (max-width: 700px) {
    .container-mbti {
        padding: 15px;
        min-width: 210px;
    }
    #data-diri-MBTI {
        margin-top: 15px;
        margin-bottom: 20px;
        overflow: hidden;
        display: block;
        /* font-size: 3vw; */
        margin-left: -10px;
    }

    .mbti {
        text-align: center;
        margin-bottom: 30px;
    }
    .mbti h1 {
        font-size: 10vw;
    }
    .mbti h5 {
        font-size: 3vw;
    }

    .karakter {
        margin-top: 0px;
        padding-top: 2px;
        margin-bottom: 25px;
        font-size: 1.1em;

        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
            rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px,
            rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    .karakter h2 {
        font-size: 150%;
    }

    .karakter ul li {
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .pekerjaan {
        text-align: center;
        margin-bottom: 20px;
        margin-left: -10px;
    }

    .saran-pekerjaan {
        padding: 30px 20px 30px 20px;
    }

    .list-pekerjaan {
        text-align: center;
        font-size: 1em;
    }
}

@media only screen and (max-width: 501px) {
    .saran-pekerjaan {
        padding: 30px 5px 30px 5px;
    }
    .list-pekerjaan ul li {
        width: 25%;
        margin-left: 8%;
    }
}

@media only screen and (max-width: 354px) {
    .data-diri-MBTI-section {
        display: block;
        /* flex-wrap: wrap; */
        justify-content: space-evenly;
        background-color: #cb243e;
        color: aliceblue;

        box-sizing: border-box;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
    }
    .karakter {
        min-width: 207px;
    }
    .saran-pekerjaan {
        padding: 30px 5px 30px 5px;
        min-width: 207px;
    }
    .list-pekerjaan ul li {
        margin-left: 25%;
    }
}

/* <--------------Saran pekerjaan-----------------> */

/*.saran-pekerjaan {
    background-color: crimson;
    width: 80%;
    margin: auto;
    border-radius: 10px;
    color: aliceblue;
    padding-right: 5%;
    padding-left: 4%;

    padding-top: 30px;
    box-sizing: border-box;
    /* font-size: large; 
    box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    -webkit-box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    -moz-box-shadow: 9px 10px 11px -3px rgba(36, 36, 36, 0.54);
    margin-bottom: 50px;
    padding-bottom: 30px;
    display: inline-block;

    width: 100%;
    text-align: center;
}

/* .pekerjaan {
    margin: auto;
    font-size: 150%;
    margin-bottom: 30px;
    text-align: center;
    display: block;
} */

/* <-------------- END Saran pekerjaan-----------------> */
/* 
.saran-profesi {
    margin: auto;
    background-color: crimson;
    width: 80%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 20px;

    text-align: center;
    font-size: 200%;
    color: aliceblue;
}

.saran-profesi ul li {
    display: inline-block; */

/* ul {
    list-style: outside disc;
    margin-left: 1em;
}
ul li {
    position: relative;
    left: 2em;
    padding-right: 1em;
} */
