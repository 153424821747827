
.bg-tesBelumTersedia {
    max-width: 600px;
    box-shadow: #242424;
}

.content-tesBelumTersedia {
    text-align: center;
}

.content-tesBelumTersedia h2 {
    font-weight: 900;
    font-size: 2.5em;
}

@media only screen and (max-width: 600px) {
    .bg-tesBelumTersedia {
        max-width: 300px;
        opacity: 0.4;
        margin-top: -30%;
    }

    .content-tesBelumTersedia {
        text-align: center;
        margin-top: -60%;
        position: relative;

        z-index: 99;
        color: #242424;
        opacity: 1;
    }

    .content-tesBelumTersedia h2 {
        font-weight: 900;
        font-size: 2em;
    }
}

@media only screen and (max-width: 300px) {
    .bg-tesBelumTersedia {
        max-width: 150px;
    }

    .content-tesBelumTersedia {
        text-align: center;

        z-index: 99;
    }

    .content-tesBelumTersedia h2 {
        font-weight: 900;
        font-size: 90%;
    }
}
